
@media screen {
  .pet-card-image {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 57%;
  }
}

@media screen and (min-width: 1024px) {
  .pet-card-image-user-policy {
    transform: translate(0, -50%);
  }
}

.ant-dropdown-menu-item svg {
  margin-right: 0.8rem;
}

.bg-dashboard-dark-orange {
  background-color: #fff8f3;
}

.bg-dashboard-light-orange {
  background-color: rgba(255, 248, 243, 0.5);
}

.pet-icon {
  @apply p-2 pb-2 bg-secondary rounded-full ring-white h-12 md:h-16 w-12 md:w-16;
}

.pet-card {
  @apply bg-white border-2 rounded-lg px-4 py-4 md:py-3 w-full hover:bg-gray-100;
}
