@font-face {
    font-family: 'Inter';
    src: url('./Inter-VariableFont_slnt,wght.ttf') format('truetype');
    font-weight: 100 900;   
}

@font-face {
    font-family: 'Merriweather';
    font-weight: 400;
    src: url('./Merriweather-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Merriweather';
    font-weight: 700;
    src: url('./Merriweather-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Merriweather';
    font-weight: 300;
    src: url('./Merriweather-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'PoppinsRegular';
    src: url('./Poppins-Regular.woff2') format('truetype');
}