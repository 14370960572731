.form-header {
  @apply text-center rounded-md bg-gray-100 p-4 pt-14 mb-6 mt-10 relative;
}

.form-header-image {
  @apply w-24 h-24 pt-1.5 rounded-full overflow-hidden border-8 border-gray-100 bg-primary absolute -top-10 left-1/2 -translate-x-1/2;
}

.form-header-title {
  @apply text-lg font-semibold;
}

.form-header-subtitle {
  @apply text-gray-600 mt-4;
}

.form-header-faq-container {
  @apply flex justify-end mt-4;
}

.form-header-faq-button {
  @apply text-blue-600 font-semibold underline;
}
