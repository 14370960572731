@tailwind base;
@tailwind components;
@tailwind utilities;



@layer base {
  @import '../assets/fonts/fonts.css';

  html,
  body {
    padding: 0;
    margin: 0;
    @apply font-sans scroll-smooth;
  }

  /* a:not(.link) {
    text-decoration: none;
  } */

  .link,
  a.link {
    @apply text-blue-600;
  }

  a.link {
    @apply underline;
  }
}

.ant-timeline-item {
  @apply my-5;
}

.ant-timeline-item-head {
  @apply bg-transparent;
}

.ant-timeline-item-tail {
  @apply border-primary top-5;
}

@layer components {
  @import './Buttons.css';
  @import './Accordion.css';
  @import './Tab.css';
  @import './Slider.css';
  @import './Inputs.css';
  @import './PlanCard.css';
  @import './FormHeader.css';
  @import './Layout.css';
  @import './Loader.css';
  @import './AntSidebar.css';
  @import './PetCard.css';
  @import './Claim.css';
  @import './Toggle.css';
  @import './Table.css';
  @import './Tag.css';
  @import './Spinner.css';
  @import './Testimonials.css';

  .actions {
    @apply sticky bottom-0 bg-white py-2 flex gap-4 mt-auto;
    z-index: 5;
  }
}

.disabled {
  @apply opacity-70 pointer-events-none;
}

.alice-carousel__dots {
  @apply my-0;
}

.alice-carousel__prev-btn {
  @apply py-0;
}

h1 {
  font-family: PoppinsRegular;
}

.ant-carousel .slick-dots li button {
  @apply bg-gray-600;
  height: 5px;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: rgb(255 116 32 / var(--tw-bg-opacity));
}

.ant-select-selection-item .vet-address .address {
  @apply hidden
}

.object-contain {
  object-fit: contain;
}