.Layout {
  @apply min-h-screen flex flex-col relative;
  /* background-color: #FFFBF8; */
}

.AuthLayoutHeader {
  @apply flex justify-between items-center p-4 bg-white border-b;
}

.LayoutFooter {
  @apply p-4 mt-auto;
}
