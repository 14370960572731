.toggle-switch-checkbox {
  height: 0;
  width: 0;
  position: absolute;
  visibility: hidden;
}

.toggle-switch {
  min-width: 20em;
  max-width: 100%;
  position: relative;
  height: 38px;
  border-radius: 45px;
  border: 2px solid #D1D5DB;
  @apply bg-white inline-block;
}

.toggle-switch .toggle-switch-button {
  width: 50%;
  height: 100%;
  border-radius: 45px;
  transition: 0.2s;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  z-index: 1;
}

.toggle-switch.checked .toggle-switch-button {
  @apply float-right;
}

.toggle-switch.checked .toggle-switch-labels span:last-child {
  @apply text-white font-bold;
}

.toggle-switch:not(.checked) .toggle-switch-labels span:first-child {
  @apply text-white font-bold;
}

.toggle-switch-labels {
  font-size: 1.1em;
  color: #858a96;
  z-index: 2;
  @apply absolute left-0 top-0 w-full h-full p-1;
}

.toggle-switch-labels span {
  @apply text-center;
  align-items: center;
  justify-content: center;
}
