@tailwind components;

.tab {
  @apply flex flex-wrap w-full;
}

.tab-header {
  @apply w-full flex list-none flex-wrap flex-row cursor-pointer;
}

.fixed-header {
  /* Mobile navbar is always 52px height */
  @apply sticky top-14 md:top-0 z-10 bg-white;
}

.tab-header-title {
  @apply flex-auto text-center text-xs font-semibold px-5 py-3 block leading-normal text-gray-500 border-b-4 border-gray-300;
}

.tab-header-title.selected {
  @apply text-black border-b-4 border-blue-400;
}

.tab-content {
  @apply flex flex-col w-full mb-6 flex-auto;
}
