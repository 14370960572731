@tailwind components;

.slider {
  @apply flex flex-col;
}

.slider-indicator {
  @apply self-center my-2 flex;
}

.slider-indicator-element {
  @apply rounded-full bg-blue-400 w-4 h-4 mx-1 bg-gray-300;
}

.slider-indicator-element.selected {
  @apply bg-blue-400;
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
  scroll-snap-type: x mandatory;
}

.slider-scroll-content {
  @apply overflow-x-scroll flex my-2;
}

.slider-scroll-div {
  @apply flex flex-nowrap;
  padding-left: 50vw;
  padding-right: 50vw;
}

.slider-content {
  @apply px-3;
  min-width: 18rem;
  scroll-snap-align: center;
}
