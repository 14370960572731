@media screen and (min-width: 1024px) {
  .dashboard-sidebar {
    flex: 0 0 250px !important;
    max-width: 300px !important;
    min-width: 300px !important;
    width: 250px !important;
  }

  .ant-layout-sider-zero-width-trigger {
    display: none;
  }
}

/* mobile menu */
.sideMenuDrawer .ant-drawer-body {
  @apply p-0;
}

.dashboard-sidebar {
  min-height: 100vh;
  max-height: 100vh;
  overflow: auto;
  position: sticky;
  top: 0;
}

.logo-container {
  text-align: start;
  margin-bottom: 10px;
  margin-top: 20px;
  padding-left: 20px
}

.ant-menu-item {
  color: #374151 !important;
  padding-left: 30px !important;
  border-radius: 20px;
}

.ant-menu-item svg {
  margin-right: 0.8rem;
}

.ant-menu-item a:hover {
  color: #393A40 !important;
}

.ant-menu-item:hover {
  background-color: #CCCFD5;
  color: #393A40 !important;
}

.ant-menu-item-active {
  color: #393A40 !important;
}

.ant-menu-item-selected {
  color: black !important;
  font-weight: 500;
  background-color: #fff8f3 !important;
  border-radius: 20px;
}

.ant-menu-item-selected a {
  color: black !important;
}

.ant-menu-item-selected svg {
  font-weight: 500;
  filter: invert(55%) sepia(56%) saturate(3072%) hue-rotate(345deg) brightness(101%) contrast(101%);
}

.ant-menu-item-selected > span > span {
  color:#FF7420 !important;
}

.ant-menu-item-selected::after {
  border-right: none !important;
}

.group-header {
  padding-left: 25px;
  font-weight: 500;
  @apply my-4 mx-0 pt-1;
  text-transform: uppercase;
  color: #374151 !important;
}

#sidebar-menu {
  padding: 0px 20px;
}

#sidebar-menu .group-header{
  padding-left: 0px;
}

#sidebar-menu .ant-menu-sub.ant-menu-inline{
  background-color: white;
}


#sidebar-menu .ant-menu-item-selected a {
  color: #FF7420 !important;
}

#sidebar-menu .ant-menu-item-selected .ant-menu-item-icon {
  color:  #FF7420 !important;
}

#sidebar-menu .ant-menu-item svg {
  margin-right: 10px
}

[data-menu-id="sidebar-menu-/plan"] {
  padding: 0px 0px !important;
}