@tailwind components;

.plan-card {
  @apply rounded-xl bg-white mb-2 mt-2 relative;
}
.plan-card-selected {
  @apply ring-4 ring-secondary-400 border-[.5px] border-gray-300
}
.plan-card-unselected {
  @apply border-[.5px] border-gray-300
}

.plan-card-plan-plus {
  @apply rounded-md bg-white px-4 pt-8 relative shadow min-w-max;
}

.plan-image {
  @apply w-20 h-20 rounded-full overflow-hidden p-4 border-white bg-white absolute -top-10 left-1/2 -translate-x-1/2;
}

.plan-card-header {
  @apply text-center text-sm;
}

.plan-card-header-name {
  @apply text-primary font-bold;
}

.plan-card-header,
.plan-card-header-price {
  @apply flex flex-col gap-1;
}
.plan-card-header-amount {
  @apply text-2xl font-semibold;
}
.plan-card-header-original-amount {
  @apply text-xs text-gray-400 line-through;
}
.plan-card-header-save {
  @apply bg-[#27aafa] rounded-xl w-1/3 text-white text-xs mt-2;
}
.plan-card-badge {
  @apply bg-[#27aafa] rounded-xl w-1/3 text-white text-xs relative bottom-10 left-6 text-center;
}

.plan-card-detail {
  @apply font-medium pt-2 
  /* sm:max-w-[350px]; */
  /* max-width: 350px !important; */
}

.plan-card-detail-row {
  @apply flex items-start gap-4;
  padding: .5rem 1rem;
}

.plan-card-detail-row .description {
  @apply flex-1;
}

.plan-card-detail-row .value {
  @apply leading-5 text-right;
}

.plan-card button::before, .plan-card-plan-plus::before {
  content: '';
}