@tailwind components;

.accordion-header {
  @apply flex flex-row items-center justify-between py-3 relative;
}

.accordion-header button::before {
  content: "";
  @apply absolute inset-0;
}

.accordion-title {
  @apply font-semibold mr-5;
}

.accordion-description {
  @apply pt-1 px-4 pb-5;
}

.accordion .accordion-header[data-opened="false"] {
  @apply border-b;
}

.accordion-header[data-opened="false"] + .accordion-description {
  @apply sr-only;
}
