
:root {
  --gray-border: #D1D5DB;
  --green: #22C55E;
}


.table > thead > tr > th, .table > tbody > tr > td {
  @apply p-2;
}

.table > thead > tr > th {
  font-weight: normal;
}

.table {
  width: 100%;
  border-collapse:separate; 
  border-spacing: 0 0.5em;
}


.table > tbody > tr > td {
  @apply bg-white;
  border-top: solid 1px var(--gray-border);
  border-bottom: solid 1px var(--gray-border);
}
.table > tbody > tr td:first-child {
  border-top-left-radius: 5px; border-bottom-left-radius: 5px;
  border-right: none;
  border-left: solid 1px var(--gray-border);
}
.table > tbody > tr td:last-child {
  border-top-right-radius: 5px; border-bottom-right-radius: 5px;
  border-left: none;
  border-right: solid 1px var(--gray-border);
}

.table > tbody > tr {
  border: solid 1px #000; 
}

.table > tbody > tr > td {
  @apply mb-2;
}

