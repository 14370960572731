@import "react-alice-carousel/lib/alice-carousel.css";

/* .alice-carousel:before {
  content: "";
  width: 10%;
  @apply absolute left-0 top-0 h-full;
  background: linear-gradient(90deg, #f9fafb, rgba(255, 255, 255, .4));
  z-index: 2;
}

.alice-carousel:after {
  content: "";
  width: 10%;
  @apply absolute right-0 top-0 h-full;
  background: linear-gradient(270deg, #f9fafb, rgba(255, 255, 255, .4));
  z-index: 2;
}

*/

.o-review-owl-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 95%;
  margin-right: 8px;
  margin-left: 8px;
  padding: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: #d8d8d8;
  border-radius: 8px;
  background-color: #fff;
  -webkit-transition: all 200ms cubic-bezier(.55, .085, .68, .53);
  transition: all 200ms cubic-bezier(.55, .085, .68, .53);
}

.o-review-owl-card:hover {
  box-shadow: 0 2px 12px 0 rgba(44, 45, 54, 0.17);
  -webkit-transform: scale(1.04);
  -ms-transform: scale(1.04);
  transform: scale(1.04);
}

.o-review-col-list {
  overflow: hidden;
  width: 100%;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.o-review-owl-title {
  font-family: Inter, sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
}

.o-review-owl-p {
  margin-bottom: 0px;
  color: #333;
  line-height: 22px;
  font-weight: 400;
}

.o-review-star {
  width: 18px;
  height: 18px;
}

.o-review-star-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 8px;
  grid-auto-columns: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.o-review-owl-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
}

.o-review-owl-img {
  width: 32px;
}

.o-review-col-list-wrapper {
  margin-top: 1px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.o-slide-text-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

