@import 'react-phone-number-input/style.css';

.PhoneInputInput {
  @apply border-none;
}

/* hide country */
.PhoneInputCountry {
  @apply hidden;
}

.input-wrapper {
  @apply flex relative flex-col;
}

.input-wrapper.disabled {
  @apply text-gray-400;
}

.input-wrapper label {
  @apply top-2;
}

.input-wrapper[data-error="true"] label {
  @apply text-red-500;
}

.input-wrapper[data-error="true"] .radio label {
  @apply text-red-500;
}

.input-wrapper[data-error="true"] .radio-group .radio label, .input-wrapper[data-error="true"] .radio-group .radio-icon label {
  @apply border-red-500;
}

.input-wrapper[data-error="true"] .input-div {
  @apply !border-red-500 !border-2;
}

.input-wrapper .error-icon {
  @apply hidden;
}

.input-wrapper[data-error="true"] .error-icon {
  @apply block;
}

.input-wrapper.material .ant-select-selector {
  padding-top: 1.25rem !important;
}

/* remove focus outline */
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  @apply mt-4;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  @apply  !pt-5 rounded-md;
  min-height: 50px !important;
}

.ant-select-selection-item {
  @apply font-medium;
}

.ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector {
  @apply  !pt-5 rounded-md;
  min-height: 50px !important;
}

/* .input-wrapper[data-error="true"] input:before {
  width: 20px;
  height: 20px;
  content: url("data: image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='16.6px' height='16.6px' %3E%3Cg stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' %3E%3Cpolygon points='0 0 24 0 24 24 0 24' /%3E%3Cpath d='M12,22 C6.477,22 2,17.523 2,12 C2,6.477 6.477,2 12,2 C17.523,2 22,6.477 22,12 C22,17.523 17.523,22 12,22 Z M11,15 L11,17 L13,17 L13,15 L11,15 Z M11,7 L11,13 L13,13 L13,7 L11,7 Z' %0Afill='currentcolor' %0AfillRule='nonzero' %0A/%3E%3C/g%3E%3C/svg%3E");
  @apply absolute top-0 right-0 mr-2 mt-1 text-red-500;
} */
.input-wrapper.material label {
  z-index: 2;
  font-weight: 400;
  line-height: 1.4375em;
  padding: 0 0;
  display: block;
  transform-origin: left top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 24px);
  position: absolute;
  top: 0.6em;
  @apply left-0;
  transform: translate(12px, 7px);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  /* pointer-events: auto; */
  user-select: none;
}

.input-wrapper.material.multiple label {
  top: 0.9em;
}

.input-wrapper.material.select label.active,
.input-wrapper.material.select label.filled {
  @apply top-4;
}

/* smalll label floating */
.input-wrapper.material label.active,
.input-wrapper.material label.filled {
  /* @apply bg-white bg-opacity-80; */
  @apply top-3;
  display: block;
  pointer-events: auto;
  transform: translate(14px, -9px) scale(0.85);
  z-index: 2;
}

.checkbox {
  @apply gap-3 flex-row;
}

.checkbox label {
  @apply order-1;
}

.input-text {
  @apply mt-2 text-gray-800;
}

.input[type='date'],
select {
  @apply w-full;
}

/* tailwind overwrite all input */
[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
[multiple],
textarea,
select {

}

input[type='checkbox'],
input[type='checkbox']:hover {
  height: 22px;
  width: 22px;
  @apply border-gray-200 border-2;
}

input[type='checkbox']:checked {
  @apply bg-secondary border-0;
}

select:disabled,
.input[type='number']:disabled,
input[type='checkbox']:disabled,
textarea:disabled {
  @apply  text-gray-400 border-0;
}

.radio-group {
  @apply flex flex-wrap gap-4;
}

.radio {
  @apply flex flex-grow gap-4;
}

.radio-icon {
  flex: calc(50% - 3rem);
  align-self: stretch;
  display: grid;
}

.radio .input,
.radio-icon .input {
  @apply sr-only;
}

.radio label,
.radio-icon label {
  @apply flex-grow py-3 flex flex-col items-center justify-end gap-2 border-2 rounded-lg;
}

.radio label span,
.radio-icon label span {
  @apply text-center;
}

.radio .input:checked+label,
.radio-icon .input:checked+label,
.radio:hover .input+label,
.radio-icon:hover .input+label {
  @apply bg-primary-50 border-primary-300 transition-[border] duration-150;
}

.radio .input:checked+label,
.radio-icon .input:checked+label {
  @apply outline outline-1 outline-primary;
}

.radio .input:checked+label>span,
.radio-icon .input:checked+label>span {
  @apply text-primary;
}

[data-error='true'] .input {
  @apply border-red-500;
}

[data-error='true'] .input-text {
  @apply text-red-500;
}

[data-error='true'] [type='text'],
[data-error='true'] [type='email'],
[data-error='true'] [type='url'],
[data-error='true'] [type='password'],
[data-error='true'] [type='number'],
[data-error='true'] [type='date'],
[data-error='true'] [type='datetime-local'],
[data-error='true'] [type='month'],
[data-error='true'] [type='search'],
[data-error='true'] [type='tel'],
[data-error='true'] [type='time'],
[data-error='true'] [type='week'],
[data-error='true'] [multiple],
[data-error='true'] textarea,
[data-error='true'] select {
  @apply !border-red-500 focus:!border-red-500;
}

[data-error='true'] .ant-select-arrow {
  @apply text-red-500;
}

[data-error='true'] .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  @apply  !border-red-500 focus:!border-red-500 border-2;
}
.ant-select-multiple .ant-select-selector .ant-select-selection-item,
.ant-select-multiple .ant-select-selector .ant-select-selection-placeholder,
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: normal;
}