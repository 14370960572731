@tailwind components;

@layer components {
    .button-base {
        @apply 
        text-sm
        font-semibold
        whitespace-nowrap
        py-2 
        px-4 
        rounded-full
        border-2
        inline-flex
        items-center
        justify-center
        gap-2;
    }
}

button.button, [type='button'].button{
    @apply 
    button-base 
    border-transparent
    bg-primary 
    text-white 
    hover:bg-primary-400
    hover:text-white
    focus:bg-primary-400
    disabled:bg-gray-100 
    disabled:text-gray-400 
    disabled:hover:border-gray-100
    disabled:cursor-not-allowed
}

button.ant-switch[type='button'] {
    background-color: #D1D5DB;
}

button.ant-switch-checked[type='button'] {
    background-color: #48BB78;
}

.button-outlined {
    @apply 
    button-base 
    bg-white 
    hover:text-secondary 
    hover:border-secondary
    focus:border-secondary
    focus:bg-secondary-50
    disabled:bg-gray-100 
    disabled:text-gray-400 
    disabled:hover:border-gray-100 
    disabled:border-gray-100
    disabled:cursor-not-allowed;
}

.button-filled {
    @apply 
    button-base 
    hover:text-black
    hover:border-secondary
    focus:border-secondary
    focus:bg-secondary
    focus:text-white
    disabled:bg-secondary 
    disabled:text-white 
    disabled:hover:border-gray-100 
    disabled:border-gray-100
    disabled:cursor-not-allowed;
}

.button-icon {
    @apply 
    p-1
    text-center
    rounded-full
    /* hover:bg-primary-400  */
    disabled:bg-gray-100 
    disabled:text-gray-400 
    disabled:hover:border-gray-100 
    disabled:border-gray-100
    disabled:cursor-not-allowed;
}

button.link {
    @apply underline;
}